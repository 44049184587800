<template>
  <Dialog
    :showHeader="false"
    :style="
      !registering_promo
        ? {
            width: '460px',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '960px !important',
            minHeight: 'fit-content',
            backgroundColor: '#ffffff',
          }
        : {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: 'fit-content',
            minHeight: 'fit-content',
            backgroundColor: '#ffffff',
          }
    "
    :modal="true"
    :dismissableMask="true"
    :closable="true"
    :closeOnEscape="true"
    @show="onShow"
    @hide="onHide"
    class="promo-modal"
  >
    <img
      src="/images/mensaje_carnaval.png"
      class="background-modal"
      v-if="!registering_promo"
    />
    <div v-if="!registering_promo" class="promo-container">
      <div>
        <img src="/images/icon_success.svg" style="width: 60px; height: 60px" />
      </div>
      <span class="title-1">Felicitaciones</span>
      <span class="mt-5 subtitle-1 mb-3"
        >Tus datos ya fueron registrados con éxito</span
      >
      <span class="w-full text-center mb-3"
        >Ya estas participando en la
        <span style="color: #cc195a"><b>Gran Rifa de Carnaval 2023</b></span>
        donde se estará sorteando los siguientes premios:</span
      >
      <span class="w-full text-center prize-item">1 Moto Bera 0km</span>
      <span class="w-full text-center prize-item">10 Televisores de 32”</span>
      <span class="w-full text-center mb-3 mt-3">
        El sorteo se realizará el próximo <b>MIERCOLES 22 DE FEBRERO</b> a las
        <b>2:00pm</b>
        en las instalaciones de la Lotería de Oriente.
      </span>
      <span
        class="w-full text-center mb-3 mt-3"
        style="color: #cc195a; font-size: 24px"
      >
        Te esperamos...</span
      >
      <div>
        <Button
          label="Aceptar"
          class="p-button-text mt-5"
          @click="onHide"
          style="width: 150px"
        />
      </div>
    </div>
  </Dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  props: {
    promo_code: {
      default: null,
    },
  },
  emits: ["close"],
  data() {
    return {
      registering_promo: false,
    };
  },
  created() {},
  methods: {
    ...mapActions("ticketoffice", ["registerPromotion"]),
    ...mapMutations("ticketoffice", ["setClientDialog"]),
    onShow() {
      this.registering_promo = true;
      if (this.activeClient && this.activeClient.dni && this.promo_code) {
        this.registerPromotion({
          code: this.promo_code,
          dni: this.activeClient.dni,
        })
          .then(() => {
            this.registering_promo = false;
          })
          .catch((err) => {
            if (err.response) {
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                detail:
                  "Fallo al Registrar la promoción. Por favor intente de nuevo ...",
                life: 6000,
              });
              setTimeout(() => {
                this.onHide();
              }, 200);
            }
          });
      } else {
        this.$emit("close");
      }
    },
    onHide() {
      this.$store.commit("ticketoffice/setActiveClient", {});
      this.$store.commit("ticketoffice/clearCurrentPlayData");
      this.setClientDialog(true);
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient"]),
  },
  watch: {},
};
</script>
<style>
@media screen and (max-width: 500px) {
  .promo-modal span {
    font-size: 12px;
  }
}
.promo-modal span {
  z-index: 999;
}
.promo-modal .p-dialog-content {
  padding: 0px !important;
}
.promo-modal .p-button.p-button-outlined,
.promo-modal .p-button.p-button-text {
  background: rgba(63, 81, 181, 0.16);
}
.promo-modal .p-button.p-button-outlined:hover,
.promo-modal .p-button.p-button-text:hover {
  background: rgba(224, 8, 8, 0.781);
}
.promo-modal .promo-container {
  width: 100%;
  height: 100vh;
  min-height: fit-content;
  max-height: 932px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  background-color: #fbfbfb75;
  padding: 10px;
}
.promo-modal .background-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  border: 0;
  padding: 0;
  z-index: 0;
}
.title-1 {
  font-size: 24px !important;
  color: #5faf58;
}
.subtitle-1 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}
.prize-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 33px;
  text-align: center;
  color: #cc195a;
}
</style>
