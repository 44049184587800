<template>
  <div class="card w-full" style="height: 100%;">
    <iframe
      v-if="selectedGame && selectedGame.url_front"
      :src="selectedGame.url_front"
      id="iframes"
      @load="onLoaded"
      v-show="iframeStatus.loaded"
    />
    <div
      v-if="selectedGame && selectedGame.url_front && !iframeStatus.loaded"
      class="loader-container"
    >
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      url_game: process.env.VUE_APP_URL_NAME,
      iframeStatus: {
        loaded: false,
        ready: false,
      },
    };
  },
  mounted() {
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, (e) => {
      let key = e.message ? "message" : "data";
      let data = e[key];

      if (data.ready) {
        this.iframeStatus.ready = data.ready;
        console.log("ready: ", this.iframeStatus.ready);
      }

      if (data.keyboard_input) {
        this.setEnabledKeyboardInput(data.keyboard_input);
        console.log("keyboard_input: ", data.keyboard_input);
      }

      if (data.game_service_request) {
        this.setCurrentGameServiceRequest(data.game_service_request);
        console.log("game_service_request: ", data.game_service_request);
        if (
          this.enabledKeyboardInput &&
          this.enabledKeyboardInput.enable === false
        ) {
          if (this.activeClient && this.activeClient.id) this.validPlayOrder();
          else {
            this.$store.dispatch("changeSystemMessage", {
              severity: "info",
              detail: "Debe Seleccionar un Cliente.",
              life: 3000,
            });
            this.setShowConsultClient(true);
          }
        }
      }

      if (data.game_option_data) {
        this.setSelectedLottery({
          id: data.game_option_data.lottery_id,
          category_code: data.game_option_data,
        });
        console.log("game_option_data: ", data.game_option_data);
      }
      // Sending a message with the active client data to the iframe.
      if (data.autocomplete_request || data.request_active_client_data) {
        this.sendActiveClientDataMessage();
        this.sendActiveTicketofficeDataMessage()
      }
      if (data.client_active_request === true) {
        console.log("client_active_request: ", data.client_active_request);
        if (this.activeClient && this.activeClient.id) {
          let iframe = document.getElementById("iframes");
          if(iframe)
          iframe.contentWindow.postMessage(
            {
              active_client_response: true,
            },
            "*"
          );
        } else {
          this.$store.dispatch("changeSystemMessage", {
            severity: "info",
            detail: "Debe Seleccionar un Cliente.",
            life: 3000,
          });
          this.setShowConsultClient(true);
        }
      }
    });
  },
  methods: {
    ...mapMutations("ticketoffice", [
      "setEnabledKeyboardInput",
      "setSelectedLottery",
      "setCurrentGameServiceRequest",
      "setIsProcessing",
      "setShowConsultClient",
    ]),
    onLoaded() {
      this.iframeStatus.loaded = true;
    },
    validPlayOrder() {
      this.setIsProcessing(true);
      const data = {
        fk_game: this.selectedGame.id,
        game_service_request: this.currentGameServiceRequest,
        fk_client: this.activeClient.id,
      };
      this.$store
        .dispatch(
          this.currentPlayData.ticket && this.currentPlayData.ticket.id
            ? "ticketoffice/addCompetitorsToPlayOrder"
            : "ticketoffice/registerPlayOrder",
          this.currentPlayData.ticket && this.currentPlayData.ticket.id
            ? {
                order_id: this.currentPlayData.ticket.id,
                data,
              }
            : data
        )
        .then((resp) => {
          this.input_data = "";
          this.$store.dispatch("changeSystemMessage", {
            severity: "success",
            detail: "Datos Registrados con Exito.",
            life: 4000,
          });
          // Sending a message to the iframe with de status of consult
          document.getElementById("iframes").contentWindow.postMessage(
            {
              status_product_consult: resp.status,
              data_product_consult: resp.data,
            },
            "*"
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
            // Sending a message to the iframe with the status of the err.response.
            document.getElementById("iframes").contentWindow.postMessage(
              {
                status_product_consult: err.response.status,
                data_product_consult: err.response.data,
              },
              "*"
            );
          }
        })
        .finally(() => {
          this.setIsProcessing(false);
        });
    },
    sendActiveClientDataMessage() {
      let data =
        this.activeClient && this.activeClient.id
          ? {
              mail: this.activeClient.fk_contact.email,
              last_name: this.activeClient.last_name,
              first_name: this.activeClient.first_name,
              dni: this.activeClient.dni,
              phone_number: this.activeClient.fk_contact.phone_number,
              client_id: this.activeClient.id,
            }
          : null;
      let iframe = document.getElementById("iframes");
      if (iframe)
      iframe.contentWindow.postMessage(
        {
          active_client: data,
        },
        "*"
      );
    },
    sendActiveTicketofficeDataMessage() {
      let data = this.user_id
      let iframe = document.getElementById("iframes");
      if (iframe)
      iframe.contentWindow.postMessage(
        {
          active_ticketoffice: data,
        },
        "*"
      );
    },
    sendCanceledOrderMessage(order_id) {
      let data = {canceled: true, external_order: order_id}
      document.getElementById("iframes").contentWindow.postMessage(
        {
          canceled_order: data,
        },
        "*"
      );
    }
  },
  computed: {
    ...mapGetters("ticketoffice", [
      "activeClient",
      "selectedGame",
      "selectedLottery",
      "currentPlayData",
      "currentGameServiceRequest",
    ]),
    ...mapGetters("userprofile", ["userData"]),
    ...mapGetters("auth", ["user_id"]),
    ...mapState("ticketoffice", [
      "is_processing",
      "enabledKeyboardInput",
      "ticketofficeOnLine",
    ]),
  },
  watch: {
    // Watch for change of selected game
    "selectedGame.id": {
      handler: function () {
        this.setEnabledKeyboardInput(false);
        this.iframeStatus.loaded = false;
        this.iframeStatus.ready = false;
      },
    },
    //watch for current order ticket change, in case of annulation
    "currentPlayData.ticket": {
      handler: function (newValue, oldValue) {
        if (newValue === null) {
          this.sendCanceledOrderMessage(oldValue.id)
        }
      }
    }, 
    // Watch for change of selected lottery option
    selectedLottery: {
      deep: true,
      handler: function () {
        {
          setTimeout(() => {
            if (
              this.selectedLottery &&
              this.selectedLottery.category_code &&
              this.enabledKeyboardInput &&
              this.enabledKeyboardInput.enable
            ) {
              if (this.$parent.$refs.keyboard_input)
                this.$parent.$refs.keyboard_input.setInputFocus();
            }
          }, 100);
        }
      },
    },
    activeClient: {
      deep: true,
      handler: function () {
        this.sendActiveClientDataMessage();
      },
    },
  },
};
</script>
<style>
iframe {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.loader-container {
  height: 100%;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}
.lds-spinner {
  color: #ccc;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(41, 36, 36);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
<style scoped>
.card.w-full {
  padding: 0.8rem !important;
}
</style>
