<template>
  <div
    class="card-screen card-keyboard flex flex-nowrap justify-content-between align-items-center mb-2"
  >
    <InputText
      v-model="input_data"
      id="i_ticketoffice_keyboard_input"
      class="w-full keyboard-input min-h-full"
      @keypress="validInput"
      autofocus
      autocomplete="off"
      type="number"
      min="0"
      inputmode="numeric"
      pattern="[0-9]*"
      @keydown.enter="validPlayOrder"
      :disabled="is_processing"
      placeholder="Ingrese la cantidad a jugar"
    />
    <Button
      class="flex md:hidden ml-2 keyboard-button"
      icon="pi pi-arrow-left"
      style="background: #e8e72b; width: 90px"
      @click="keyboardDeleteInput"
      :disabled="is_processing"
    />
    <Button
      class="flex md:hidden ml-2 keyboard-button"
      icon="pi pi-send rotate-45"
      style="background: #46af3d; width: 90px"
      :disabled="is_processing"
      :loading="is_processing"
      @click="validPlayOrder"
      v-tooltip.bottom="'Jugar'"
    />
  </div>
  <div class="card-keyboard mb-0 md:mb-2">
    <div class="w-full hidden md:block">
      <div class="grid">
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="1"
            @click="keyboardInput(1)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="2"
            @click="keyboardInput(2)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="3"
            @click="keyboardInput(3)"
            :disabled="is_processing"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="4"
            @click="keyboardInput(4)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="5"
            @click="keyboardInput(5)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="6"
            @click="keyboardInput(6)"
            :disabled="is_processing"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="7"
            @click="keyboardInput(7)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="8"
            @click="keyboardInput(8)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="9"
            @click="keyboardInput(9)"
            :disabled="is_processing"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            icon="pi pi-arrow-left"
            style="background: #e8e72b"
            @click="keyboardDeleteInput"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            label="0"
            @click="keyboardInput(0)"
            :disabled="is_processing"
          />
        </div>
        <div class="col-4">
          <Button
            class="w-full keyboard-button"
            icon="pi pi-send rotate-45"
            style="background: #46af3d"
            :disabled="
              is_processing ||
              !selectedGame ||
              !selectedGame.id ||
              !selectedLottery
            "
            :loading="is_processing"
            @click="validPlayOrder"
            v-tooltip.bottom="'Jugar'"
          />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex flex-row justify-content-center pt-3 md:pt-2 pb-2 pb-0">
        <Button
          class="w-full keyboard-button px-2 mr-1 text-2xl sm:text-3xl"
          icon="hidden md:block pi pi-times-circle"
          label="ANULAR"
          style="background: #cc195a; color: #fff"
          :disabled="
            is_processing || !(currentPlayData && currentPlayData.plays.length)
          "
          @click="setShowConsultClient(true)"
          v-tooltip.bottom="'Anular Jugada'"
        />
        <Button
          v-if="ticketofficeOnLine || ['taquilla'].includes($store.getters['auth/role'])"
          class="w-full keyboard-button px-2 mx-1 text-2xl sm:text-3xl"
          icon="hidden md:block pi pi-user"
          label="CLIENTE"
          style="background: #004af1; color: #fff"
          :disabled="is_processing"
          @click="setShowConsultClient(true)"
          v-tooltip.bottom="'Establecer Cliente'"
        />
        <Button
          class="w-full md:hidden keyboard-button px-2 text-2xl sm:text-3xl"
          label="PAGAR"
          @click="setPayDialog(true)"
          style="background: #262626; color: #fff"
          :disabled="is_processing || !currentPlayData.plays.length"
          v-tooltip.bottom="'Procesar Pago'"
        />
      </div>
    </div>
  </div>
  <div class="hidden md:block card-pay">
    <Button
      class="w-full keyboard-button"
      label="PAGAR"
      @click="setPayDialog(true)"
      style="background: #262626; color: #fff"
      :disabled="is_processing || !currentPlayData.plays.length"
      v-tooltip.bottom="'Procesar Pago'"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      input_data: "",
    };
  },
  created() {
    if (!this.$parent.clientDialog) setTimeout(this.setInputFocus, 60);
  },
  methods: {
    ...mapActions("ticketoffice", ["registerPlayOrder"]),
    ...mapMutations("ticketoffice", ["setIsProcessing", "setShowConsultClient", "setPayDialog"]),
    setInputFocus() {
      let elem = document.getElementById("i_ticketoffice_keyboard_input");
      if (elem) {
        elem.focus();
      }
    },
    keyboardInput(input) {
      if (!this.is_processing) this.input_data += String(input);
      this.setInputFocus();
    },
    keyboardDeleteInput() {
      if (!this.is_processing) this.input_data = ""; //this.input_data.slice(0, -1);
      this.setInputFocus();
    },
    validInput(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },
    validPlayOrder() {
      // validar que se selecciono una opcion de Juego
      if (
        !this.selectedGame ||
        !this.selectedGame.id ||
        !this.selectedLottery
      ) {
        this.$store.dispatch("changeSystemMessage", {
          severity: "info",
          detail: "Debe Seleccionar una opción de Juego.",
          life: 3000,
        });
        return;
      }
      // Validar que se establecieron los datos del cliente
      else if (!this.activeClient.dni) {
        this.$store.dispatch("changeSystemMessage", {
          severity: "info",
          detail: "Debe Seleccionar un Cliente.",
          life: 3000,
        });
        this.setShowConsultClient(true);
      } else if (!Number(this.input_data) || this.is_processing) return;
      else {
        this.setIsProcessing(true);
        let game_service_request = this.currentGameServiceRequest;
        // TODO: refactorizar para que este proceso este en un solo sitio, también esta en GameFrame
        if (this.enabledKeyboardInput)
          game_service_request[this.enabledKeyboardInput.key] = Number(
            this.input_data
          );
        const data = {
          fk_game: this.selectedGame.id,
          game_service_request,
          fk_client: this.activeClient.id
        }
        this.$store
          .dispatch(
            this.currentPlayData.ticket && this.currentPlayData.ticket.id
              ? "ticketoffice/addCompetitorsToPlayOrder"
              : "ticketoffice/registerPlayOrder",
            this.currentPlayData.ticket && this.currentPlayData.ticket.id
              ? {
                  order_id: this.currentPlayData.ticket.id,
                  data
                }
              : data

          )
          .then(() => {
            this.input_data = "";
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Datos Registrados con Exito.",
              life: 4000,
            });
          })
          .catch((err) => {
            //console.log(err)
            if (err.response) {
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                summary: err.response.data.message,
                detail: this.getFirstError(err.response.data.errors),
                life: 10000,
              });
            }
          })
          .finally(() => {
            this.setIsProcessing(false);
            setTimeout(this.setInputFocus, 60);
          });
      }
    },
  },
  computed: {
    ...mapGetters("ticketoffice", [
      "activeClient",
      "selectedGame",
      "selectedLottery",
      "currentPlayData",
      "currentGameServiceRequest",
    ]),
    ...mapState("ticketoffice", [
      "is_processing",
      "enabledKeyboardInput",
      "ticketofficeOnLine",
    ]),
  },
};
</script>
<style>
.keyboard-input {
  height: 50px;
  background: #ebebeb;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  text-align: right;
  font-size: 36px;
  font-weight: 700;
  padding: 15px;
}
.keyboard-input::placeholder {
  font-size: 50%;
  font-style: italic;
  font-weight: 300;
  text-align: left;
}
.keyboard-button, .keyboard-button.p-button-icon-only {
  height: 75px;
  width: 85px;
  background: #D7D7D7;
  border-radius: 1px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 1px;
}
.keyboard-button .pi {
  font-size: 36px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media only screen and (max-width: 768px) {
  .keyboard-button {
    height: 50px;
  }
}
</style>
