<template>
  <meta name="viewport" content="width=980">
  <div class="grid">
    <div class="col-12">
      <div
        class="flex flex-row justify-content-between flex-wrap w-full mt-1 py-3 md:py-0 sm:mt-0 p-0 md:p-1"
        style="min-height: calc(100vh - 220px);"
      >
        <div
          v-if="enabledKeyboardInput && enabledKeyboardInput.enable"
          class="col pb-0 md:flex-order-1 lg:flex-order-1 xl:flex-order-3 notprintContent"
          style="max-width: 450px; min-width: 400px"
        >
          <KeyboardInput ref="keyboard_input" />
        </div>
        <div
          class="col iframe-movil flex-order-0 lg:flex-order-3 xl:flex-order-2 notprintContent"
          style="min-width: 400px; height: auto"
        >
          <GameFrame />
        </div>
      </div>
    </div>
    <ConsultClient
      class="notprintContent"
      v-model:visible="clientDialog"
      @cancel="setClientDialog(false)"
      @complete="()=> { setClientDialog(false); if(this.isCarnavalPromo) this.show_promo_modal = true; }"
    />
    <pay-options
      v-model:visible="getPayDialog"
      @showPrint="showPrint"
      @cancel="setPayDialog(false)"
    />
    <promo-modal v-if="isCarnavalPromo" v-model:visible="show_promo_modal" :promo_code="isCarnavalPromo ? this.carnaval_promo_code : null" @close="show_promo_modal=false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import KeyboardInput from "../../components/ticketoffice/KeyboardInput.vue";
import GameFrame from "../../components/ticketoffice/GameFrame.vue";
import ConsultClient from "../../components/ticketoffice/modals/ConsultClient.vue";
import PayOptions from "../../components/ticketoffice/modals/PayOptions.vue";
import PromoModal from "../../components/ticketoffice/modals/PromoModal.vue";

export default {
  components: {
    KeyboardInput,
    GameFrame,
    ConsultClient,
    PayOptions,
    PromoModal
  },
  data() {
    return {
      gameList_visible: false,
      carnaval_promo_code: "be544d362e6910a9a5827067c2fb4141",
      show_promo_modal: false
    };
  },
  mounted() {
    document
      .getElementsByClassName("layout-main-container")[0]
      .classList.add("padding-topbar-ticketoffice");
  },
  beforeUnmount() {
    document
      .getElementsByClassName("layout-main-container")[0]
      .classList.remove("padding-topbar-ticketoffice");
  },
  created() {
    if (this.isCarnavalPromo) {
      this.$store.commit("ticketoffice/setActiveClient", {});
      this.$store.commit("ticketoffice/clearCurrentPlayData");
      this.setClientDialog(true);
      return;
    }
    this.loadTicketOfficeSession()
      .then(() => {
        if (this.$store.getters["ticketoffice/activeGameList"].length >= 1)
          this.setDefaultGame();
        else {
          this.unwatch = this.$store.watch(
            (state, getters) => getters["ticketoffice/activeGameList"],
            () => {
              this.setDefaultGame();
              this.unwatch();
            }
          );
        }
        if (!this.activeClient.dni) this.setClientDialog(true);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {

    ...mapActions("ticketoffice", [
      "loadTicketOfficeSession",
    ]),
    ...mapMutations("ticketoffice", [
      "setGetUserBalance", "setSelectedGame", "setClientDialog", "setPayDialog",
      "setShowPrint"
    ]),
    showGameList(visible) {
      this.gameList_visible = visible;
    },
    setDefaultGame() {
      if (
        this.$route.query.game_option &&
        String(this.$route.query.game_option).trim().length > 1
      ) {
        this.setSelectedGame({});
        let game = this.$store.getters["ticketoffice/activeGameList"].find(
          (e) =>
            String(e.name).toLowerCase() ===
            String(this.$route.query.game_option).trim().toLowerCase()
        );
        if (game) {
          this.setSelectedGame(game);
        } else {
          this.setSelectedGame(
            this.$store.getters["ticketoffice/activeGameList"][0]
          );
        }
      } else if (this.selectedGame.id) {
        this.setSelectedGame(
          this.$store.getters["ticketoffice/activeGameList"].find(
            (e) => e.id === this.selectedGame.id
          ) || this.$store.getters["ticketoffice/activeGameList"][0]
        );
      } else
        this.setSelectedGame(
          this.$store.getters["ticketoffice/activeGameList"][0]
        );
    },
    showPrint(value) {
      this.setShowPrint(value);
      this.setPayDialog(false);
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "selectedGame"]),
    ...mapState("ticketoffice", ["enabledKeyboardInput", "clientDialog", "payDialog"]),
    getPayDialog: {
      get() { return this.payDialog; },
      set(value) { this.setPayDialog(value); }
    },
    isCarnavalPromo() {
      return (this.$route.query.promotion && this.$route.query.promotion === this.carnaval_promo_code)
    }
  },
  watch: {
    clientDialog() {
      setTimeout(() => {
        if (this.$refs.keyboard_input)
          this.$refs.keyboard_input.setInputFocus();
      }, 50);
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.close-ticket-preview-left-button {
  border-radius: 0px 25px 25px 0px;
  width: 2rem;
  margin-top: 40%;
  height: 6rem;
  left: -12px;
}

.left-button-position{
  position: relative;
}

.left-button-height{
  height: 5px 
}
/* @media (min-width: 1024px) {
.iframe-kiosco{
  min-height: 75vh
}
}
@media (min-width: 1300px) {
.iframe-kiosco{
  min-height: 71vh
}
} */
</style>
